import React from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react";
import AppStore from "../../../stores/appStore";
import { uiState } from "./flightPageModel";
import { FlightPlan } from '../../common/flightPlan';
import { FlightElementList } from "../../common/flightElementList";
import common from '../../common.module.css'
//CurrentFlight displays the state of the current flight/exercise in progress
//Orchestrates the voice recognition and synthesis
//Controls progress in flight

export const FlightPage = observer(() => {

    const model = AppStore.getInstance().uiStore.flightPageModel

    if (model.uiState === uiState.noFlight) return <div>No flight loaded. Go back...</div>

    return (<div className={common.page}>

        {model.uiState !== uiState.flightEnded && model.flight?.flightPlan &&
            <FlightPlan key={crypto.randomUUID()} flp={model.flight.flightPlan} head={model.flightPlanHeading}></FlightPlan>
        }

        <FlightElementList />
    </div>)
})

export default FlightPage




