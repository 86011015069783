import { observer } from "mobx-react";
import { NavButton } from "../../common/navButton";
import React from "react";
import common from "../../common.module.css";
import styles from "./groundSchoolPage.module.css"
import { Outlet, Route, Routes } from "react-router-dom";
import { IcaoPage } from "../icaoPage/icaoPage";
import { ReadbackPage } from "../readbackPage/readbackPage";
import { PositionPage } from "../positionPage/positionPage";

export const GroundSchoolPage = observer(() => {
    return (<>
        <Routes>
            <Route index element={<GroundSchoolPageContent />} />
            <Route path="icao" element={<IcaoPage />} />
            <Route path="readback" element={<ReadbackPage />} />
            <Route path="position" element={<PositionPage />} />
            <Route path="icao" element={<div>Todo</div>} />
        </Routes>
    </>
    );
});

const GroundSchoolPageContent = observer(() => {
    return (
        <div className={common.page + ' ' + styles.page + ' ' + styles.navButtonStack}>
            <div className={common.pageHeader}>
                <h1>Focus on the basic elements.</h1>
            </div>
            <NavButton bg="bg4" heading="ICAO alphabet" subheading="Pratice the code words of the ICAO radio telephony alphabet" link={'icao'} subject={'icao'} />
            <NavButton bg="bg1" heading="Readbacks" subheading="Read back clearances and instructions given by ATC" link={'readback'} subject={'readback'} />
            <NavButton bg="bg3" heading="Position reports" subheading="Give correct position reports in contrlled and un-controlled airspace" link={'position'} subject={'position'} />
            <NavButton bg="bg2" heading="Emergency" subheading="Practice emergency procedures" link={'emergency'} subject={'emergency'} />
        </div>
    )
});